import { Config } from './interface';

// prettier-ignore
const config: Config = {
  identityPoolId: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID || 'ap-northeast-1:9731f9c9-6cc5-482e-870a-1a0596962e7d',
  graphQlEndpoint: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT || 'https://ttmi5iimlbej5acpxgql5sf6ue.appsync-api.ap-northeast-1.amazonaws.com/graphql',
  staffUserPoolId: process.env.NEXT_PUBLIC_STAFF_USER_POOL_ID || 'ap-northeast-1_DljLyol1q',
  staffUserPoolClientId: process.env.NEXT_PUBLIC_STAFF_USER_POOL_CLIENT_ID || '1pmtiea7pnroac883fmn4tsgqq',
  teacherUserPoolId: process.env.NEXT_PUBLIC_TEACHER_USER_POOL_ID || 'ap-northeast-1_azWvY8WmJ',
  teacherUserPoolClientId: process.env.NEXT_PUBLIC_TEACHER_USER_POOL_CLIENT_ID || '5sjebc603b8s52a64dk9k7s95e',
  studentUserPoolId: process.env.NEXT_PUBLIC_STUDENT_USER_POOL_ID || 'ap-northeast-1_cgx4uIHx4',
  studentUserPoolClientId: process.env.NEXT_PUBLIC_STUDENT_USER_POOL_CLIENT_ID || '6411mopuegekgkhaadg9cr2h4b',
  sentryEnvironment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'pro',
};

export default config;
