const dictionary = {
  ja: {
    'User does not exist.': 'メールアドレスかパスワードが正しくありません。',
    'The username should either be a string or one of the sign in types':
      'メールアドレスとパスワードを入力してください。',
    'CUSTOM_AUTH is not enabled for the client.':
      'パスワードを入力してください。',
    'Incorrect username or password.':
      'メールアドレスかパスワードが正しくありません。',
    'Password attempts exceeded':
      '試行回数が上限に達しました。しばらく待ってからお試しください。',
    'Invalid verification code provided, please try again.':
      '確認コードが正しくありません。',
    'Attempt limit exceeded, please try after some time.':
      '試行回数が上限に達しました。しばらく待ってからお試しください。',
    'user.nickname: Required attribute cannot be deleted.\n':
      '名前に空文字列を設定することはできません。',
    'The value of the attribute nickname must have a length less than or equal to 2048 characters':
      '名前は2,048文字以下で入力してください。',
    'Password did not conform with policy: Password must have uppercase characters':
      'パスワードには大文字を含める必要があります。',
    'Password does not conform to policy: Password must have lowercase characters':
      'パスワードには小文字を含める必要があります。',
    'Password does not conform to policy: Password must have symbol characters':
      'パスワードには記号を含める必要があります。',
    'Password does not conform to policy: Password must have numeric characters':
      'パスワードには数字を含める必要があります。',
    'Password did not conform with policy: Password not long enough':
      'パスワードが短すぎます。',
  },
};

export default dictionary;
