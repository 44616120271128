import { atom } from 'jotai';
import { useAtomValue } from 'jotai/utils';

import {
  signIn,
  signOut,
  getIsSignedIn,
  subscribeAuthState,
} from '@common/frontend/utils/auth';

type AuthState = 'loading' | 'authenticated' | 'unauthenticated';

const authStateAtom = atom<AuthState>('loading');

// 初めてatomが参照された際に実行される初期化処理
authStateAtom.onMount = setAtom => {
  // 状態が変わった際の反映
  subscribeAuthState(isSignedIn =>
    setAtom(isSignedIn ? 'authenticated' : 'unauthenticated'),
  );

  // 初期状態の反映
  getIsSignedIn().then(isSignedIn =>
    setAtom(isSignedIn ? 'authenticated' : 'unauthenticated'),
  );
};

export const useAuth = () => {
  const status = useAtomValue(authStateAtom);

  return {
    status,
    signIn,
    signOut,
  };
};
