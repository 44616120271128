import { Hub, Auth, I18n } from 'aws-amplify';

/**
 * targetがObject型でmessageというフィールドを持つことを確認する
 */
const hasMessage = (target: unknown): target is { message: string } =>
  target instanceof Object && Object.hasOwn(target, 'message');

export const getIsSignedIn = async (): Promise<boolean> => {
  try {
    await Auth.currentAuthenticatedUser({ bypassCache: true });

    return true;
  } catch {
    // do nothing
  }

  return false;
};

type SubscribeAuthStateCallback = (isSignedIn: boolean) => void;

// 戻り値の関数を呼ぶことで監視を解除できる
export const subscribeAuthState = (
  callback: SubscribeAuthStateCallback,
): (() => void) =>
  Hub.listen('auth', ({ payload: { event } }) => {
    if (event === 'signIn') {
      callback(true);
    } else if (event === 'signOut' || event === 'tokenRefresh_failure') {
      callback(false);
    }
  });

type SignInInput = {
  username: string;
  password: string;
};

type SignInOutput = {
  error?: string;
};

type SignIn = (input: SignInInput) => Promise<SignInOutput>;

export const signIn: SignIn = async ({ username, password }) => {
  try {
    await Auth.signIn({ username, password });

    return {};
  } catch (error: unknown) {
    if (hasMessage(error)) {
      return { error: I18n.get(error.message) };
    }

    return { error: '不明なエラーが発生しました' };
  }
};

export const signOut = async () => {
  await Auth.signOut();
};

type UpdatePasswordInput = {
  currentPassword: string;
  newPassword: string;
};

type UpdatePasswordOutput = {
  error?: string;
};

type UpdatePassword = (
  input: UpdatePasswordInput,
) => Promise<UpdatePasswordOutput>;

export const updatePassword: UpdatePassword = async ({
  currentPassword,
  newPassword,
}) => {
  try {
    const user = await Auth.currentAuthenticatedUser();

    await Auth.changePassword(user, currentPassword, newPassword);

    return {};
  } catch (error: unknown) {
    if (hasMessage(error)) {
      return { error: I18n.get(error.message) };
    }

    return { error: '不明なエラーが発生しました' };
  }
};
