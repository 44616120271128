import { useContext, useMemo } from 'react';

import { context } from './context';
import { Props } from './props';

const useHook = ({ title: titleBody }: Props) => {
  const { titlePrefix } = useContext(context);

  const title = useMemo(() => {
    let result = '';

    if (titleBody) {
      result = `${titleBody} | ユニバープラス`;
    } else {
      result = 'ユニバープラス';
    }

    if (titlePrefix) {
      result = `[${titlePrefix}] ${result}`;
    }

    return result;
  }, [titleBody, titlePrefix]);

  return { title };
};

export default useHook;
