import {
  from,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  InMemoryCacheConfig,
} from '@apollo/client';
import { init as initializeSentry } from '@sentry/browser';
import { Amplify, Auth, I18n } from 'aws-amplify';
import { createAuthLink, AuthOptions, AUTH_TYPE } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import devConfig from './config/development';
import { Config } from './config/interface';
import proConfig from './config/production';
import AmplifyI18nDictionary from './i18n/dictionary';
import fragmentTypes from './types/fragmentTypes.json';

// ((global.window || {}) as any).LOG_LEVEL = 'DEBUG';

type Output = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apolloClient: ApolloClient<any>;
};

export type AmplifyConfigureProps = {
  Auth: {
    identityPoolId: string;
    userPoolWebClientId: string;
    userPoolId: string;
    region: string;
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  aws_appsync_graphqlEndpoint: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  aws_appsync_region: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  aws_appsync_authenticationType: string;
};

export type Input = {
  sentryDsn: string;
  overrideConfig: (
    config: Config,
    amplifyConfig: AmplifyConfigureProps,
  ) => AmplifyConfigureProps;
  inMemoryCacheConfig?: InMemoryCacheConfig;
};

const initialize = ({
  sentryDsn,
  overrideConfig,
  inMemoryCacheConfig = {},
}: Input): Output => {
  const isProduction = process.env.NEXT_PUBLIC_ENV_NAME === 'pro';
  const config: Config = isProduction ? proConfig : devConfig;

  initializeSentry({
    dsn: sentryDsn,
    tracesSampleRate: 0,
    environment: config.sentryEnvironment,
    enabled: ['pro', 'dev'].includes(config.sentryEnvironment),
  });

  const region = 'ap-northeast-1';

  I18n.putVocabularies(AmplifyI18nDictionary);
  I18n.setLanguage('ja');

  const amplifyConfig: AmplifyConfigureProps = {
    Auth: {
      region,
      identityPoolId: config.identityPoolId,
      userPoolWebClientId: 'overrideConfigにて上書き必須',
      userPoolId: 'overrideConfigにて上書き必須',
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    aws_appsync_graphqlEndpoint: config.graphQlEndpoint,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    aws_appsync_region: 'ap-northeast-1',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  };

  Amplify.configure(overrideConfig(config, amplifyConfig));

  const auth: AuthOptions = {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => {
      try {
        return (await Auth.currentSession()).getIdToken().getJwtToken();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.info('Session Expired', error);
        // セッションが切れていたら明示的にログアウト処理を呼ぶ
        // これによりHubでAuthのイベントを購読している箇所が起動し、ログインフォームへのリダイレクトが行われる
        Auth.signOut();

        return '';
      }
    },
  };

  const httpLink = createHttpLink({ uri: config.graphQlEndpoint });

  const link = from([
    createAuthLink({ url: config.graphQlEndpoint, region, auth }),
    createSubscriptionHandshakeLink(
      { url: config.graphQlEndpoint, region, auth },
      httpLink,
    ),
  ]);

  const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache({
      ...inMemoryCacheConfig,
      possibleTypes: fragmentTypes.possibleTypes,
    }),
  });

  return { apolloClient };
};

export default initialize;
