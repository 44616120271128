import { Helmet } from 'react-helmet-async';

import useHook from './hook';
import { Props } from './props';

const Component: React.FC<Props> = props => {
  const { title } = useHook(props);

  return (
    <Helmet>
      <title>{title}</title>
      <link
        key="apple-touch-icon"
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        key="icon-32"
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        key="icon-16"
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
    </Helmet>
  );
};

export default Component;
