import { Config } from './interface';

// prettier-ignore
const config: Config = {
  identityPoolId: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID || 'ap-northeast-1:71bf5f31-5909-4e5a-9573-fbeafbda95e8',
  graphQlEndpoint: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT || 'https://zhjf5zgf7vexpnqzizaea777xq.appsync-api.ap-northeast-1.amazonaws.com/graphql',
  staffUserPoolId: process.env.NEXT_PUBLIC_STAFF_USER_POOL_ID || 'ap-northeast-1_ILH6GrCjX',
  staffUserPoolClientId: process.env.NEXT_PUBLIC_STAFF_USER_POOL_CLIENT_ID || 'v2ag8q9m4qaobphjkibth8s51',
  teacherUserPoolId: process.env.NEXT_PUBLIC_TEACHER_USER_POOL_ID || 'ap-northeast-1_RerQFfz5P',
  teacherUserPoolClientId: process.env.NEXT_PUBLIC_TEACHER_USER_POOL_CLIENT_ID || '1f18q95dbbl8ue15q88epps49u',
  studentUserPoolId: process.env.NEXT_PUBLIC_STUDENT_USER_POOL_ID || 'ap-northeast-1_KGqtchOw7',
  studentUserPoolClientId: process.env.NEXT_PUBLIC_STUDENT_USER_POOL_CLIENT_ID || '1letq2l8f4qubc3tgi4d26k80p',
  sentryEnvironment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'dev',
};

export default config;
